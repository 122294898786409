import { io } from 'socket.io-client';
import Peer from 'peerjs';
import { makeid } from 'utils/randomUtils';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TOAST_PROPERTIES, TOAST_PROPERTIES_FIX } from "constants/toasts";
import { Translation } from 'react-i18next';
import { ENVIRONMENT } from 'enums/environment';

// Configurations
console.log('[ENV] ' + process.env.REACT_APP_ENV)
const config = {
    [ENVIRONMENT.LOCAL]: {
        protocol: "http",
        host: "localhost",
        port: 2000,
        users: "users",
        ws: "nodes",
        resources: "resources",
        peer: "peerjs"
    },
    [ENVIRONMENT.CYPRESS]: {
        protocol: "http",
        host: "localhost",
        port: 2000,
        users: "users",
        ws: "nodes",
        resources: "resources",
        peer: "peerjs"
    },
    [ENVIRONMENT.MR]: {
        protocol: "http",
        host: "nginx",
        port: 2000,
        users: "users",
        ws: "nodes",
        resources: "resources",
        peer: "peerjs"
    },
    [ENVIRONMENT.RECETTE]: {
        protocol: "https",
        host: "recette.roomsparty.com",
        port: 443,
        users: "users",
        ws: "nodes",
        resources: "resources",
        peer: "peerjs"
    },
    [ENVIRONMENT.PRODUCTION]: {
        protocol: "https",
        host: "roomsparty.com",
        port: 443,
        users: "users",
        ws: "nodes",
        resources: "resources",
        peer: "peerjs"
    }
}


// Extract data from config
export const protocol = config[process.env.REACT_APP_ENV]['protocol'];
export const host = config[process.env.REACT_APP_ENV]['host'];
export const port = config[process.env.REACT_APP_ENV]['port'];
export const urlUsers = config[process.env.REACT_APP_ENV]['users'];
export const urlResources = config[process.env.REACT_APP_ENV]['resources'];
export const urlWs = config[process.env.REACT_APP_ENV]['ws'];
export const urlPeer = config[process.env.REACT_APP_ENV]['peer'];


// Initiaze browserId in localStorage
let browserId = localStorage.getItem('browserId');
if (!browserId) {
    browserId = makeid(24);
    localStorage.setItem('browserId', browserId);
}
console.log('[BROWSER_ID] ' + browserId)

// Initialize socketClient
export const socketClient = io(`${host}:${port}`, {
    transports: ["websocket"],
    query: {
        browserId: browserId
    }
});
let socketConnected = false;
let serverUnavailableMessage = false;
let serverUnaivalableToast = undefined

socketClient.on('connect', () => {
    if (socketConnected) {
        console.log('[WS] Server a été relancé !');
        toast.info(
            <Translation>
                {(t) => <>{t("app.socket.server_restarted")}</>}
            </Translation>
            , TOAST_PROPERTIES_FIX);
        if (serverUnaivalableToast) {
            toast.dismiss(serverUnaivalableToast);
        }
    }
    console.log('[WS] Connexion établie avec succès !');
    socketConnected = true;
    serverUnavailableMessage = false;
});

socketClient.on("connect_error", (err) => {
    if (!serverUnavailableMessage) {
        serverUnavailableMessage = true;
        serverUnaivalableToast = toast.error(
            <Translation>
                {(t) => <>{t("app.socket.server_not_accessible")}</>}
            </Translation>
            , TOAST_PROPERTIES_FIX);
        console.log(`[WS] Erreur de connexion : ${err.message}`);
    }
});

socketClient.on("connect_failed", (err) => {
    console.log(`[WS] Connection a échoué : ${err.message}`);
});

socketClient.on("disconnect", (reason) => {
    console.log(`[WS] Déconnexion ${reason}`);
});



// Initialize peer
export const peerClient = new Peer(makeid(24), { host: host, port: port, path: urlPeer });
peerClient.on('open', () => {
    console.log('[PEER] ' + peerClient.id);
    socketClient.emit('media/peerId', peerClient.id);
});


// Initialize axios
export const axiosInstance = axios.create();

axiosInstance.defaults.withCredentials = true;

axiosInstance.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        switch (error.response.data) {
            case 'NOT_HAS_RIGHT_ERROR':
                toast.error(
                    <div data-qa="toast-right-insufficient">
                        <Translation>
                            {(t) => <>{t("app.right_insufficient")}</>}
                        </Translation>
                    </div>
                    , TOAST_PROPERTIES);
                break;
            case 'USER_NOT_CONNECTED':
            default:
                break;
        }
    }

    return Promise.reject(error);
});

if ([
    ENVIRONMENT.RECETTE,
    ENVIRONMENT.PRODUCTION
].includes(process.env.REACT_APP_ENV)) {
    const sideId = process.env.REACT_APP_ENV === ENVIRONMENT.RECETTE ? '2' : '1';
    var _paq = window._paq = window._paq || [];
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
    _paq.push(['trackPageView']);
    _paq.push(['enableLinkTracking']);
    (function () {
        var u = "//matomo.solunew-admin.ovh/";
        _paq.push(['setTrackerUrl', u + 'matomo.php']);
        _paq.push(['setSiteId', sideId]);
        var d = document, g = d.createElement('script'), s = d.getElementsByTagName('script')[0];
        g.async = true; g.src = u + 'matomo.js'; s.parentNode.insertBefore(g, s);
    })();
}
